import React from 'react';
import {SubMenu} from "../elements";

// Components
import InfoCard from "./InfoCard";
import ContentCard from "./ContentCard";
import {Col3, Col4, Col9, Row} from "components/elements";

function AlchemyMenu(props)
{
	const clearFilters = ()=>
	{
		if (localStorage.getItem("location") !== null)
			localStorage.removeItem('location')

		if (localStorage.getItem("capability") !== null)
			localStorage.removeItem('capability')
	}

	return ( <SubMenu showSubMenu={props.open}>
	    <Col3>
		    <InfoCard rows={3} cols={2} data={props.data}/>
	    </Col3>
	    <Col9>
        <Row>
		{
			props.data?.subNavigation?.cards?.map((card, index) =>
			{
				return (
					<Col4 onClick={clearFilters} key={index}>
						<ContentCard rows={3} cols={2} data={card} borderBottom="none" minHeight="105px"/>
					</Col4>
				)
			})
		}
        </Row>
	    </Col9>
	</SubMenu> )
}

export default AlchemyMenu;