
import React from 'react'

import { PanelGrid } from './elements'

import PanelTitle from './Title'
import EmptyComponent from './EmptyComp'
import ImageComponent from './ImageComp'
import TextComponent from './TextComp'


function Panel({ data, number, imagePadding, fromHome, sliderType }) {
  /*
  ** Rules on how supplied content will be rendered:
  **	- currently, this code deals with images and text only as content (handling Regions still to come)
  **	- the top left is fixed with blue bg, card #, and title
  **	- in the main left area, image and/or text components are stacked.  same in the right main area.
  **	- right top: if not given, it's a 2col Empty component; otherwise, all given content entries are rendered
  */

  const blankImage = [{
    imageAsset:
      'https://images.ctfassets.net/fzkxe4nzcokr/5z2EmtXqY7mtI2GYSYCyjV/1f2cbc61ffea792074381ad97a1ccc21/blank.png?h=300'
  }]

  const emptyContent = [{ empty: true }]

  const [title, setTitle] = React.useState('')
  const [mainLeft, setMainLeft] = React.useState(blankImage)
  const [mainRight, setMainRight] = React.useState(blankImage)
  const [topRight, setTopRight] = React.useState(emptyContent)
  const [topSecondRight, setTopSecondRight] = React.useState(emptyContent)
  const [mainSecondRight, setMainSecondRight] = React.useState(emptyContent)

  React.useEffect(() => {
    if (data) {
      setTitle(data.title)
      setMainLeft(data.leftContent.slice(0, 2))
      setMainRight(data.rightContent.slice(0, 2))
      if (data.topRightContent)
        setTopRight(data.topRightContent.slice(0, 2))
      if (data.topSecondRight)
        setTopSecondRight(data.topSecondRight.slice(0, 2))
      if (data.mainSecondRight)
        setMainSecondRight(data.mainSecondRight.slice(0, 2))
    }
  }, [data])

  const TR_render = (item, nItems, key) => {
    return _render(item, fromHome ? 1 : 2, (nItems > 1) ? 1 : 2, key)
  }
  const TR_second_render = (item, nItems, key) => {
    return _render(item, fromHome ? 1 : 2, (nItems > 1) ? 1 : 2, key)
  }


  const ML_render = (item, nItems, key) => {
    return _render(item, 3, (nItems > 1) ? 1 : 2, key)
  }

  const MR_render = (item, nItems, index, key) => {

    let nRows = (nItems === 1) ? (fromHome ? 3 : 2) : (index > 0) ? 2 : 1;
    return _render(item, nRows, 2, key)
  }
  const MR_second_render = (item, nItems, index, key) => {

    let nRows = (nItems === 1) ? (fromHome ? 3 : 2) : (index > 0) ? 2 : 1;
    return _render(item, nRows, 2, key)
  }


  const _render = (item, nRows, nCols, key) => {
    if (item['imageAsset']) {
      return <ImageComponent imagePadding={imagePadding} key={'img-' + key} image={item['imageAsset']}
        rows={nRows}
        cols={nCols}
      />
    }

    if (item['text']) {
      return <TextComponent key={'txt-' + key} description={item['text']}
        rows={nRows}
        cols={nCols}
      />
    }

    /* By default, generate a 2col empty with given # rows */

    return <EmptyComponent key={key} borderLeft="none" rows={nRows} cols={nCols} />
  }

  return (
    <PanelGrid className={`${sliderType} section ${number} ${fromHome ? "homePanel" : ""} `} >
      <PanelTitle
        titleNumber={number}
        title={title}
        cols="2"
        rows="1"
        color="#fff"
        backgroundColor={fromHome ? "#D12FFF" : "#000"}
      />

      { topRight.map((item, index) => { return TR_render(item, topRight.length, `${"top-" + index}`) })}

      {
        fromHome && topSecondRight.map((item, index) => { return TR_second_render(item, topSecondRight.length, `${"top-" + index}`) })
      }

      { mainLeft.map((item, index) => { return ML_render(item, mainLeft.length, `${"left-" + index}`) })}
      { mainRight.map((item, index) => { return MR_render(item, mainRight.length, index, `${"right-" + index}`) })}

      {
        fromHome && mainSecondRight.map((item, index) => { return MR_second_render(item, mainSecondRight.length, index, `${"right-" + index}`) })
      }
    </PanelGrid>
  )
}

export default Panel
