import styled from 'styled-components';
import {
    TitleXl,
    TitleSm, 
    Col4,
    Row,
} from "components/elements";
import {
    sizeLaptop,
    sizeMobileLg,
    sizeTablet
} from "components/devices";
import { ViewAllWrap } from 'components/ViewAll';



export const LeadersBySpecialtyContainer = styled.div`
    padding: 6% 2.5%;
    ${sizeMobileLg(`
        padding: 15% 4.5% 20%;
        `
    )}
    ${TitleXl}{
        padding: 0 5px 4%;
        font-size: 48px;
        line-height: 57px;
        ${sizeMobileLg(`
            font-size: 36px;
            line-height: 43px;
            padding: 0 0 4%;
        `)}
    } 
    ${Row}{
  
        ${sizeMobileLg(`
          flex-direction: column;
          row-gap: 0 !important;
        `)}
    ${Col4}{
        ${sizeMobileLg(`
          flex: 0 0 100%;
          max-width: 100%;
          padding: 20px 0;
      `)} 
    }
    }
    ${ViewAllWrap}{
        ${TitleSm}{
            ${sizeLaptop(`
                font-size: 12px; 
                line-height: 14px;
        `)}
        }
        ${sizeLaptop(`
            width: 180px !important;
        `)}

        ${sizeMobileLg(`
            width: 198px !important;
            height: 29px !important;
        `)}
    }

`

export const CardContainer = styled.div`
  width: 95%;
  margin: 0 auto;
  min-height: 217px;
  height: 100%;
  padding: 35px 0 20px;
  background-image:  ${({backgroundImage}) => `url('${backgroundImage}')`};
  background-position: center center no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  ${sizeTablet(`
    padding: 15px 0;
  `)}
  ${sizeMobileLg(`
     min-height: 168px;
     padding: 35px 0 20px;
     width: 100%;
  `)}

  ${TitleXl}{
    font-size: 24px;
    line-height: 28px;
    color: #fff;
    text-align: center;
    height: 45px;
    ${sizeMobileLg(`
        font-size: 20px;
        line-height: 24px;
    `)}
  }
`