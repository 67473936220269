import React, { useState, useEffect } from 'react';
import FooterAnimation from "components/FooterAnimation";
import PurpleAnimHeader from 'components/PurpleAnimHeader';
import { WideScreenContainer } from "../../components/elements";
import { r3cPageContent } from "../../constants";
import { findContent, getImageDimensions } from "../../generalHelpers";
import LoadingHoc from "../../hoc/LoadingHOC";
import { Helmet } from "react-helmet";
import useRememberScrollPosition from "../../hooks/useRememberScrollPosition";
import AboutUsTopWhiteSection from 'components/AboutUsTopWhiteSection';
import AboutUsPurpleSection from 'components/AboutUsPurpleSection';
import AboutUsBottomWhiteSection from 'components/AboutUsBottomWhiteSection';
import LeadershipTeamSection from 'components/LeadershipTeamSection';
import LeadersBySpecialtyCards from 'components/LeadersBySpecialtyCards';

function About(props) {
  const { setLoading } = props;
  const [heroSectionData, setHeroSectionData] = useState({});
  const [textSectionData, setTextSectionData] = React.useState({});
  const [leadershipCards, setLeadershipCards] = useState({});
  const [additionalLeadershipCards, setAdditionalLeadershipCards] = useState({});
  const [boardOfDirectorsCards, setBoardOfDirectorsCards] = useState({});
  const [sixBuCapabilities, setSixBuCapabilities] = useState({});
  const [rankings, setRankings] = useState({});
  const [esgSection, setEsgSection] = useState({});
  const [deiSection, setDeiSection] = useState({});
  const [numberSection, setNumberSection] = useState({});
  const [seoData, setSeoData] = useState({});

  useEffect(() => {
    fetch(r3cPageContent + '?route=/about').then((response) => response.json()).then((page) => {
      let seo = page.seo;
      let image = page?.seo?.image?.split('.');

      page?.seo?.image ? getImageDimensions(page?.seo?.image, function(width, height) {
        setSeoData({
          ...seo,
          mimeType: image[image.length - 1],
          seoImageHeight: height,
          seoImageWidth: width
        });

      }) : setSeoData(seo);

      setHeroSectionData(findContent(page.contents, 'heroArea'));
      setLeadershipCards(findContent(page.contents, 'cardSelector', 1));
      setAdditionalLeadershipCards(findContent(page.contents, 'cardSelector', 2));
      setBoardOfDirectorsCards(findContent(page.contents, 'cardSelector', 3));
      setSixBuCapabilities(findContent(page.contents, 'twoColumnCopyImage', 1));
      setEsgSection(findContent(page.contents, 'twoColumnCopyImage', 2));
      setDeiSection(findContent(page.contents, 'twoColumnCopyImage', 3));
      setRankings(findContent(page.contents, 'twoColumnCopyImage', 4));
      setNumberSection(findContent(page.contents, 'region'));
      setTextSectionData(findContent(page.contents, 'textArea'));
      setLoading(false);
    });
    // eslint-disable-next-line
  }, []);

  useRememberScrollPosition('about');

  // Debounce function to handle rapid clicks
  function debounce(func, wait) {
    let timeout;
    return function(...args) {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), wait);
    };
  }

  return (
    <div className="pageWrapper about">
      <Helmet>
        <title>{seoData.title}</title>
        <link rel="canonical" href={window.location.pathname} />
        <meta name="description" content={seoData.description} />
        <meta name="og:description" content={seoData.description} />
        <meta name="og:title" content={seoData.title} />
        <meta name="og:url" content={window.location.protocol + "//" + window.location.host + window.location.pathname} />
        <meta name="og:type" content="website" />
        <meta name="og:site_name" content="Real Chemistry" />
        <meta name="og:image" content={window.location.protocol + seoData.image} />
        <meta name="og:image:type" content={"image/" + seoData?.mimeType} />
        <meta name="og:image:width" content={seoData?.seoImageWidth} />
        <meta name="og:image:height" content={seoData?.seoImageHeight} />
      </Helmet>

      <PurpleAnimHeader
        sectionLabel={heroSectionData.label}
        sectionTitle={heroSectionData.textArea}
        heroIntro={textSectionData.text}
        headerSplit={"3"}
        backgroundImage={"/images/about-us-header.svg"}
        backgroundImgMbl={"/images/about-us-head-mbl.svg"}
        backgroundImgTitle={""}
        header={"Life science companies are seeking answers, but all they get are buzzwords"}
        subHead={"So much talk. So little transformation. "}
        titleMdPadding={"18% 0 0"}
        maxWidth="33%"
        marginRight="12%"
        headerMaxWidth="75%"
      />

      <WideScreenContainer>
        <div id="who-we-are">
          <AboutUsTopWhiteSection content={sixBuCapabilities} />
        </div>

        <div id="our-rankings">
          <AboutUsPurpleSection content={rankings} />
        </div>

        <div id="esg">
          <AboutUsTopWhiteSection content={esgSection} width="80%" />
        </div>

        <div id="global-inclusion">
          <AboutUsTopWhiteSection content={deiSection} width="80%" />
        </div>

        <div id="values">
          <AboutUsBottomWhiteSection content={numberSection} />
        </div>

        <div id="leadership">
          <LeadershipTeamSection executiveLeaders={leadershipCards} additionalLeaders={additionalLeadershipCards} boardOfDirectors={boardOfDirectorsCards} />
        </div>

        <LeadersBySpecialtyCards headline={"Our leadership teams, by specialty"} ctaText={'VIEW LEADERSHIP'} />
      </WideScreenContainer>
      <FooterAnimation
        svgDesktop="/images/purple-footer.svg"
        svgMobile="/images/purple-footer-mbl.svg"
        borderTop="none"
      />
    </div>
  );
}

export default LoadingHoc(About);
