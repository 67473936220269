
const cfConfig =
{
	appId : '6cUzXBo54zKIb82sNoi7fT',
	baseUrl : 'https://api.contentful.com',
	deliveryToken : 'E4oAIZ6lDvbtWCaUzITDTnNZhXlwxh0VuvXxcB5e_30',
	environment : undefined,
	managementToken : 'CFPAT-REPcOOgYY0lylF23nI5QPX9PG03eEGSKaLpOQ1un9RY',
	orgId : '1LD4kNSAfBXhSc2fPheOLg',
	previewUrl : 'preview.contentful.com',
	previewToken : 'uYMssq_OHHoLwo_dkV-hqUuZhTdF2aJcNKjhpOWU1WA',
	spaceId : 'fzkxe4nzcokr'
}

const rcConfig =
{
	awsRegion : 'us-east-1',
	caching : true,
	handleContentfulEvents : true,
	headerName : 'RC-Main-Nav',
	footerName : 'RC-Footer',
	jobMapId : '__JobMap__',
	rssFeedId : '__RssFeed__',
	verbose : true
}

const wdConfig =
	{
		autoPublish : true,
		username : 'ISU_JobPosting',
		password : '2001Workday!',
		lambdaKey : 'WgW2Cb2d8RyjNEtkam6PWA',
		// url : 'wd2-impl-services1.workday.com/ccx/service/customreport2/w2o/apujol/W2O_External_Job_Postings_for_Careers_Page'
		url : 'services1.myworkday.com/ccx/service/customreport2/w2o/globalhris/W2O_External_Job_Postings_for_Careers_Page'
	}

const pardotConfig = {
	businessUnitId:'0Uv0b0000008OPkCAM',
	accessTokenUrl:'https://login.salesforce.com/services/oauth2/token',
	instanceUrl:'https://w2o.my.salesforce.com/services/data/v50.0',
	pardotApiEndpoint:'http://pi.pardot.com/api',
	consumerKey:'3MVG9dZJodJWITStC.0u5gJ48yX8sUAdUP67bYm2QDXgqgMSyVu5t6OflsXVC_lSXFlPhllxAHPi2nhoQa8.E',
	consumerSecret:'2A3E1F14C1F89B4F2A7E6AE50B3D841372C14A387E6D851E1B3609CA1A7DAE37',
	username:'events@w2ogroup.com',
	password: 'SanFran2021'
}

let env = process.env.RC_ENV
let recaptchaSecret = '6LdzECAcAAAAABSWqnsAFWmc7tRocXC5Gj4n5_z8'

if ( env === undefined )
{
	console.warn( `RC_ENV ${env} unknown; using dev` )
	env = 'dev'
}

cfConfig.environment = env

module.exports = { ...rcConfig, environment : env, contentful : cfConfig, workday : wdConfig, recaptchaSecret : recaptchaSecret,pardotConfig:pardotConfig }

