
const cfapi = require( 'contentful' )
const cfcfg = require('./cfcfg')

const createPreviewClient = ( env ) =>
{
	return cfapi.createClient
	({
		environment : env,
		accessToken : cfcfg.previewToken,
		space : cfcfg.spaceId,
		host : cfcfg.previewUrl
	})
}

const processResponse = async (rsp, scalar = true) =>
{
	let entries = rsp.items ? rsp.items : [rsp]

	for ( let i = 0; i < entries.length; i++ )
	{
		let entry = entries[i]

		if ( entry.body )
			entry = JSON.parse(entry)

		let rval = restructure(entry)
		fixFields(rval)
		await postProcess(rval)
	}

	return scalar ? entries[0] : entries
}

const postProcess = async (entry) =>
{
	if (entry.ctype === 'page')
	{
		delete entry.header
		delete entry.footer

		//await jobs.processPage(entry)
	}

	return entry
}

/*
const articles = []
const processArticle = async (article) =>
{
	if ( articles.length == 0 )
		articles = await fetchEntries('articles')

	addMoreArticles( entry, articles )
}

const addMoreArticles = async (entry, alist) =>
{
	let mlist = []

	alist.sort(() => Math.random() - 0.5)

	for (let i = 0; i < alist.length; i++)
	{
		let a = alist[i]

		if (a.slug === entry.slug)
			continue

		mlist.push(a)

		if (mlist.length == 4)
			break
	}

	entry.moreArticles = mlist
}
*/

/*
** The recursive function below goes through the data one more time, and mungs some field
** props to achieve compliance with a standard param set (as expected by the FE code).
**	__Articles__
**		a.type -> icon & label
**		a.author + '/' + a.date -> summary
**	__Benefits__
**		b.country -> icon (US or EU)
**	__Jobs__
**		j.location -> source
**	? __Cards__ ?
**		c.label -> date
**		c.summary -> source
*/

const fixFields = (json) =>
{
	if (!json)
		return

	/*
	** @todo -- need to capture this info whenever we refresh from CF (unless it turns out the DAM isn't CF)
	*/

	const globe = 'https://images.ctfassets.net/fzkxe4nzcokr/2ZEn0tPgsU4iw8vjGOtdIn/5cbaff41db72e1244e3061f3b924cebf/News-icon-black.svg'
	const eyeCn = 'https://images.ctfassets.net/fzkxe4nzcokr/4r0tfXaNPJMjxazVJ2pjoK/77846866443c2afb3876d3211b7e387e/eye-con-black.png'

	if (Array.isArray(json))
	{
		for (let i = 0; i < json.length; i++)
			fixFields(json[i])
	}

	else if (typeof (json) === 'object')
	{
		if (json.ctype === 'articles')
		{
			if (json.type.toUpperCase() === 'NEWS')
				json.icon = globe
			else
				json.icon = eyeCn

			json.label = json.type
			json.summary = json.author + ' / ' + json.date
			json.detailLink = '/article' + json.slug
		}

		else if (json.ctype === 'benefits')
			json.icon = globe		// @todo -- this should really be based on country i assume

		else if (json.ctype === 'jobs')
		{
			json.detail = json.detail.content[0].content[0].value
			json.source = json.location
		}

		else for (const prop in json)
			fixFields(json[prop])
								// Here we fix up the media a bit -- still WIP
		if (json.media)
		{
			if (Array.isArray(json.media))		// @todo -- media fields are not yet an array in the CM ...
			{
				json.image = json.media[0]
				json.mobileImage = json.media[1]
			}
			else
				json.image = json.mobileImage = json.media

			delete json.media
		}
	}
}

/*
** Restructures the JSON to be sent to the back app, squashing out some of the CF data.
*/

const restructure = (json) =>
{
	if (typeof (json) !== 'object')
		return json
	for (const prop in json)
	{
		if (prop === 'fields')
		{
			let fields = json.fields

			for (const fname in fields) {
				json[fname] = json.fields[fname]
				const v = json[fname]
								// if assets dimensions and/or size are also needed,they're available here...
				if (v?.fields?.file)
					json[fname] = v.fields.file.url

				restructure(json[fname])
			}

			delete json.fields
		}

		else if ( prop === 'metadata' && (!json.metadata.length || !json.metadata.tags?.length) )
			delete json.metadata

		else if ( prop === 'sys' )
		{
			json['cfid'] = json.sys.id
			json['ctype'] = json.sys?.contentType?.sys?.id
			delete json.sys
		}

		else
			restructure(json[prop])
	}

	return json
}

export { createPreviewClient, processResponse }

