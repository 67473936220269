import React, {useState} from 'react'

import {
    SectionContentContainer,
    WideScreenContainer
} from 'components/elements'

import {AccordionWrapper} from './elements'

import CallToAction from 'components/CallToAction'
import FooterAnimation from 'components/FooterAnimation'
import GPAccordion from 'components/GPAccordion'
import InnerHero from 'components/InnerHero'
import RichText from 'components/RichText'
import SideBySideSection from "components/SideBySideSection"

import {findContent, getImageDimensions} from 'generalHelpers'
import {r3cPageContent} from 'constants/index'
import LoadingHoc from "../../hoc/LoadingHOC";
import Timeline from "../../components/Timeline";
import {Helmet} from "react-helmet";
import useRememberScrollPosition from "../../hooks/useRememberScrollPosition";


function DEI(props) {
    const {setLoading} = props;
    const [heroSectionData, setHeroSectionData] = useState(null)
    const [accordionData, setAccordionData] = useState(null)
    const [accordionState, setAccordionState] = useState([])
    // const [relatedContent, setRelatedContent] = useState([])
    // const [jumpData, setJumpData] = useState({})
    const [seoData, setSeoData] = useState({})
    const [infoSectionData, setInfoSectionData] = React.useState([])
    const [graphData, setGraphData] = useState({});

    const theCardName = (i) => {
        return 'aCard-' + i
    }

    React.useEffect(() => {
        fetch(r3cPageContent + '?route=/dei').then((response) => response.json()).then((page) => {
            let seo = page.seo;

            let image  = page?.seo?.image?.split('.')

            page?.seo?.image ?  getImageDimensions(page?.seo?.image,function (width,height){
                setSeoData({
                    ...seo,
                    mimeType:image[image.length - 1],
                    seoImageHeight:height,
                    seoImageWidth:width
                })

            }) :    setSeoData(seo)

            setHeroSectionData(findContent(page.contents, 'heroArea'))
            // setJumpData(findContent(page.contents, 'jumpArea'))
            setGraphData(findContent(page.contents, 'timeline'))

            setInfoSectionData(findContent(page.contents, 'cards'))

            // setRelatedContent(page.relatedContent)
            let aData = findContent(page.contents, 'accordion')
            setAccordionData(aData)

            let aState = []
            aData.items.map((item, index) => {
                aState.push(
                    {
                        'id': theCardName(index),
                        'state': false
                    })
                return true
            })
            setAccordionState(aState)
            setLoading(false)
        })
        // eslint-disable-next-line
    }, [])

    const accordionStateChange = (cid, cState) => {
        let aState = []

        for (let i = 0; i < accordionState.length; i++) {
            let c = accordionState[i]

            if (c.id === cid) c.state = cState

            aState.push(c)
        }

        setAccordionState(aState)
    }

    const renderAccordion = (item, index) => {
        let accordion = null

        if (accordionState) {
            let jsx = []
            let content = null

            if (item.ctype === 'textArea')
                content = item.text

            else if (item.ctype === 'region')			// if card content is a region, then it's assumed to be:
            {							// text-area-1, cta-card, text-area-2 ...
                let eList = item.elements

                if (eList.length > 0) {
                    for (const el of eList){
                        if(el.ctype === 'textArea'){
                            jsx.push( <div>
                                <SectionContentContainer>
                                    <RichText body={el.text}/>
                                </SectionContentContainer>
                            </div>)
                        }
                        if(el.ctype === 'cards'){
                            jsx.push(<div>
                                <CallToAction
                                    label={el.label}
                                    title={el.title}
                                    body={el.detail}
                                    ctaLink={el.ctaLink}
                                    ctaText={el.ctaText}
                                />
                            </div>)
                        }

                    }
                }
            }

            const cName = theCardName(index)

            accordion = <GPAccordion
                accordionState={accordionState.filter((a) => a.id === cName)}
                handleStateChange={accordionStateChange}
                cardId={cName}
                key={cName}
                title={item.name}
                content={content}
                children={jsx}
            />
        }

        return accordion
    }
    useRememberScrollPosition('services');

    return (
        <div>
            <Helmet>
                <title>{seoData.title}</title>
                <link rel="canonical" href={ window.location.pathname} />
                <meta name="description" content={seoData.description} />
                <meta name="og:description" content={seoData.description} />
                <meta name="og:title" content={seoData.title} />
                <meta name="og:url" content={window.location.protocol + "//" + window.location.host + window.location.pathname} />
                <meta name="og:type" content="website" />
                <meta name="og:site_name" content="Real Chemistry" />

                <meta name="og:image" content={window.location.protocol +seoData.image} />
                <meta name="og:image:type" content={"image/"+seoData?.mimeType} />
                <meta name="og:image:width" content={seoData?.seoImageWidth} />
                <meta name="og:image:height" content={seoData?.seoImageHeight} />
            </Helmet>
            <InnerHero
                sectionLabel={heroSectionData?.label}
                sectionTitle={heroSectionData?.textArea}
                autoPlay
                // videoSrc={heroSectionData?.bgVideo}
                disable={true}
                fromInsights={true}
                videoPoster={'//downloads.ctfassets.net/fzkxe4nzcokr/5XdM4nCCetHMEnLM45Jfb2/5c42f8641fdd9f3abaa84010273c97ee/GettyImages-1245131026-2.png'}
            />
            <WideScreenContainer>
                <SideBySideSection
                    label={infoSectionData.label}
                    title={infoSectionData.title}
                    detail={infoSectionData.detail}
                    borderBottom="1px solid #707070"
                />
                {
                    graphData?.ctype ? <Timeline
                            data={graphData}
                        />
                        :''
                }

                {/*{*/}
                {/*	mainContent?.text ? <SectionContent className="services">*/}
                {/*		<RichText body={mainContent?.text} />*/}
                {/*	</SectionContent> : ""*/}
                {/*}*/}

                <AccordionWrapper>
                    {
                        accordionData &&
                        accordionData.items.map((item, index) => {
                            return renderAccordion(item, index)
                        })
                    }
                </AccordionWrapper>

                {/*<ReleventContainer>*/}
                {/*    <TitleContainer borderTop={'1px solid #707070'}>*/}
                {/*        <RelevantTitle>*/}
                {/*            <TitleXl>Related Content</TitleXl>*/}
                {/*        </RelevantTitle>*/}
                {/*    </TitleContainer>*/}
                {/*    <Marquee link="/all-articles" title="More"/>*/}
                {/*</ReleventContainer>*/}

                {/*{relatedContent?.length && <BlogCardSlider slides={relatedContent}/>}*/}
                {/* Blog Cards Mobile */}
                {/*<BlogCardsMobile>*/}
                {/*    {relatedContent?.map((content, index) => {*/}
                {/*        let dstr = (content.author ? (content.author + ' / ') : '') + content.date*/}
                {/*        return (*/}
                {/*            <BlogCard*/}
                {/*                key={index}*/}
                {/*                borderLeft="1px solid #707070"*/}
                {/*                image={content.image}*/}
                {/*                mobileImage={content.mobileImage}*/}
                {/*                date={dstr}*/}
                {/*                mobileTitle={content.title}*/}
                {/*                description={content.title}*/}
                {/*                link={content.detailLink}*/}
                {/*            />*/}
                {/*        )*/}
                {/*    })}*/}
                {/*</BlogCardsMobile>*/}
            </WideScreenContainer>
            <FooterAnimation
				svgDesktop="/images/purple-footer.svg"
				svgMobile="/images/purple-footer-mbl.svg"
				borderTop="none"
			/>
        </div>
    )
}

export default LoadingHoc(DEI)

