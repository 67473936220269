import React, { useCallback, useEffect, useState } from "react";

import {
  MediaCardWrap,
  MediaCardHeader,
  MediaImgWrap,
  MediaImg,
  MediaCardBody,
  MediaCta,
  MediaCardFooter,
  BlogLogo,
  MediaCtaExt,
  ContentContainer,
  CtaArrowContainer

} from "./elements";

import { BodySm, Mobile, TitleSm, Desktop, TitleXs, Row } from "components/elements";
import ReleaseIcon from "../../assets/icons/ReleaseIcon";
import BlogIcon from "../../assets/icons/BlogIcon";
import PodcastIcon from "../../assets/icons/PodcastIcon";
import EventsIcon from "../../assets/icons/EventsIcon";
import moment from "moment";
import { smartTruncate } from "../../generalHelpers";
import MediaType from "../MediaType";
import ArrowIcon from "../../assets/icons/ArrowIcon";

const icons = {
  'PressRelease': <ReleaseIcon />,
  'Podcast': <PodcastIcon />,
  'Event': <EventsIcon />,
  'BlogPost': <BlogIcon />
}
const ctas = {
  'PressRelease': 'READ MORE',
  'Podcast': 'LISTEN',
  'Event': 'LEARN MORE',
  'BlogPost': 'READ MORE'
}
// const links = {
//     'PressRelease': '/press-releases',
//     'Podcast': '/podcasts',
//     'Event': '/events',
//     'BlogPost': '/blog'
// }

const textBtn = {
  'PressRelease': 'Press Release',
  'Podcast': 'Podcast',
  'Event': 'Event',
  'BlogPost': 'Blog Post'
}

function MediaCard({
  cardType,
  btnText,
  size,
  date,
  img,
  link,
  bodyText,
  tabletPad,
  height,
  isLoading,
  headerPad,
  blogLogo,
  ctaText,
  flexDirection,
  imgPadding,
  padding,
  mediaCardHeadPad,
  mediaBodyPad,
  greyBackground,
  flex


}) {

  const [imageLoading, setImageLoading] = useState(true);
  const [mobileView, setMobileView] = useState(false);


  const handleWindowResize = useCallback(event => {
    const newHeight = window.innerWidth;
    if (newHeight > 767) {
      setMobileView(false)
    }
    if (newHeight < 767) {
      setMobileView(true)
    }
  }, []);


  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize)

  }, [handleWindowResize])


  return (
    <MediaCardWrap
      flexDirection={flexDirection}
      padding={padding}
      height={height}
      background={greyBackground}
    >
      <Row>
        {mobileView && (
          <MediaCardHeader tabletPad={tabletPad}>
            <MediaType
              headerPad={headerPad}
              icon={icons[cardType]}
              text={textBtn[cardType]}
            />
          </MediaCardHeader>
        )

        }
        {flexDirection === "column" && !mobileView && cardType !== "News" && (
          <MediaCardHeader mediaCardHeadPad={mediaCardHeadPad}>
            <MediaType
              headerPad={headerPad}
              icon={icons[cardType]}
              text={textBtn[cardType]}
            />
            <Desktop headerPad={headerPad}>
              <BodySm className={'desktop-date'}
                textAlign={'right'}>{moment(date).format("MMM D, YYYY")}</BodySm>
            </Desktop>
          </MediaCardHeader>

        )}

        <MediaImgWrap flex={flex} imgPadding={imgPadding}>
          <MediaImg onLoad={() => {
            setImageLoading(false)
          }} src={(!imageLoading && !isLoading) ? img : '/images/skeleton.gif'} alt={'Media image cover'} />
        </MediaImgWrap>
        <ContentContainer>
          {
            flexDirection !== "column" && cardType !== "News" ?
              <MediaCardHeader>
                {!mobileView && (
                  <MediaType
                    headerPad={headerPad}
                    icon={icons[cardType]}
                    text={textBtn[cardType]}
                  />
                )}

                <Desktop headerPad={headerPad}>
                  <BodySm className={'desktop-date'}
                    textAlign={'right'}>{moment(date).format("MMM D, YYYY")}</BodySm>
                </Desktop>
              </MediaCardHeader> : ""
          }

          <MediaCardBody mediaBodyPad={mediaBodyPad} className={date || btnText ? "" : "media-body-lg"}>
            {
              blogLogo ?
                <BlogLogo src={blogLogo} alt={''} />
                : ""
            }
            {
              size === "large" ?
                <TitleSm className={"article title"} margin={'0 0 16px 0'}>
                  {smartTruncate(bodyText, 140)}
                </TitleSm>
                :
                <TitleXs className={"article title"} margin={'0 0 16px 0'}>
                  {smartTruncate(bodyText, 100)}
                </TitleXs>
            }
            {
              date ? <Mobile>
                <BodySm className={'mobile-date'}>{moment(date).format("MMMM D, YYYY")}</BodySm>
              </Mobile> : ""
            }


            <MediaCardFooter>
              {
                cardType === 'News' ?
                  <CtaArrowContainer>
                    <MediaCtaExt href={link} target={'_blank'}>{ctaText ? ctaText : "Read More"}</MediaCtaExt>
                    <ArrowIcon className={'arrow-icon'} />
                  </CtaArrowContainer>
                  : <MediaCta className={"article cta"} to={link}>
                    {
                      ctas[cardType] ? ctas[cardType] : 'Read More'
                    }
                  </MediaCta>
              }
            </MediaCardFooter>

            {/* Media Wrap Link */}
            {/* {
                    cardType === 'News' ? <MediaLinkWrapExt href={link} target={'_blank'}/>
                        : <MediaLinkWrap className={"article link"} to={link}/>
                } */}

          </MediaCardBody>
        </ContentContainer>
      </Row>
    </MediaCardWrap>
  )
}

export default MediaCard;
