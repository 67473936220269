import React from 'react';
import {BLOCKS, INLINES, MARKS} from "@contentful/rich-text-types";
import {Link} from "react-router-dom";

// Import Style Components
import {
    BodyRg,
    TitleXl,
    TitleLg,
    TitleMd,
    TitleSm,
    TitleXs,
    H6,
    Bold,
    UL,
    OL,
    LI,
    Quote,
    BlogLink,
    Image, 
    Video
} from "../elements";


/*
      Styled Attributes gets the following options:
      for p,h1,h2,h3,h4,h5,h6:
      fontSize,
      textTransform,
      color,
      for ul,ol,li:
      margin,
      padding
 */

export const richTextOptions = (styleAttributes) => {
    return {
        renderMark: {
            [MARKS.BOLD]: text => <Bold>{text}</Bold>,
        },
        renderMark: {
            [MARKS.SUPERSCRIPT]: text => <sup>{text}</sup>,
        },
        renderNode: {
            // BLOCKS
            [BLOCKS.PARAGRAPH]: (node, children) => {
                return (
                    <BodyRg
                        color={styleAttributes?.color}
                        fontSize={styleAttributes?.fontSize}
                        textTransform={styleAttributes?.textTransform}
                        margin={styleAttributes?.margin}
                    >
                        {children}
                    </BodyRg>)
            },
            [BLOCKS.HEADING_1]: (node, children) => {
                return (
                    <TitleXl
                        color={styleAttributes?.color}
                        fontSize={styleAttributes?.fontSize}
                        textTransform={styleAttributes?.textTransform}
                        margin={styleAttributes?.margin}
                    >
                        {children}
                    </TitleXl>)
            },
            [BLOCKS.HEADING_2]: (node, children) => {
                return (
                    <TitleLg
                        color={styleAttributes?.color}
                        fontSize={styleAttributes?.fontSize}
                        textTransform={styleAttributes?.textTransform}
                        margin={styleAttributes?.margin}
                    >
                        {children}
                    </TitleLg>)
            },
            [BLOCKS.HEADING_3]: (node, children) => {
                return (
                    <TitleMd
                        color={styleAttributes?.color}
                        fontSize={styleAttributes?.fontSize}
                        textTransform={styleAttributes?.textTransform}
                        margin={styleAttributes?.margin}
                    >
                        {children}
                    </TitleMd>)
            },
            [BLOCKS.HEADING_4]: (node, children) => {
                return (
                    <TitleSm
                        color={styleAttributes?.color}
                        fontSize={styleAttributes?.fontSize}
                        textTransform={styleAttributes?.textTransform}
                        margin={styleAttributes?.margin}
                    >
                        {children}
                    </TitleSm>)
            },
            [BLOCKS.HEADING_5]: (node, children) => {
                return (
                    <TitleXs
                        color={styleAttributes?.color}
                        fontSize={styleAttributes?.fontSize}
                        textTransform={styleAttributes?.textTransform}
                        margin={styleAttributes?.margin}
                    >
                        {children}
                    </TitleXs>)
            },
            [BLOCKS.HEADING_6]: (node, children) => {
                return (
                    <H6
                        color={styleAttributes?.color}
                        fontSize={styleAttributes?.fontSize}
                        textTransform={styleAttributes?.textTransform}
                        margin={styleAttributes?.margin}
                    >
                        {children}
                    </H6>)
            },
            [BLOCKS.UL_LIST]: (node, children) => {
                return (
                    <UL
                        padding={'20px 0 5px 0'}
                        margin={styleAttributes?.margin}
                    >
                            {children}
                    </UL>)
            },
            [BLOCKS.OL_LIST]: (node, children) => {
                return (
                    <OL
                        padding={styleAttributes?.padding}
                        margin={styleAttributes?.margin}
                    >
                        {children}
                    </OL>)
            },
            [BLOCKS.LIST_ITEM]: (node, children) => {
                return (
                    <LI
                        padding={styleAttributes?.padding}
                        margin={styleAttributes?.margin}
                    >
                        {children}
                    </LI>)
            },
            [BLOCKS.QUOTE]: (node, children) => {
                return (
                    <Quote
                        color={styleAttributes?.color}
                        fontSize={styleAttributes?.fontSize}
                        textTransform={styleAttributes?.textTransform}
                    >
                        {children}
                    </Quote>)
            },
            [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
                let img = node?.data?.target?.file || node?.data?.target?.fields?.file
                let videoURL = node?.data?.target?.fields?.file
                let contentType = node?.data?.target?.fields?.file?.contentType                
                if(contentType === "video/mp4"){
                  return  <Video
                  objectFit={'contain'}
                  width={'100%'}
                  isSafari={false}
                  playing={true}
                  loop
                  controls
                  autoPlay={false}
                  playsInline={true}
                >
                  <source src={videoURL?.url} />
                </Video>
                }else{
                    return <Image src={img?.url} alt={'Body'}/>
                }
            },
            [INLINES.EMBEDDED_ENTRY]: (node, children) => {
               
                let img = node?.data?.target?.imageAsset;
                if (node?.data?.target?.hyperlink) {
                    if (node?.data?.target?.hyperlink?.charAt(0) === '/' || node?.data?.target?.hyperlink?.charAt(0) === '#') {
                        return <Link to={node?.data?.target?.hyperlink}>
                            <Image src={img} alt={'Body'}/>
                        </Link>
                    } else {
                        return <a href={`${node?.data?.target?.hyperlink}`} target="_blank" rel="noopener noreferrer">
                            <Image src={img} alt={'Body'}/>
                        </a>
                    }
                } else {
                    return <Image src={img} alt={'Body'}/>
                }


            },
            [INLINES.ENTRY_HYPERLINK]: (node, children) => {
                // If you are using contenful.js sdk, the referenced entry is resolved
                // automatically and is available at `node.data.target`.
                let url = '';
                if (node.data.target.ctype === 'page') {
                    url = node.data.target.route;
                } else if (node.data.target.ctype === 'articles') {
                    url = `/article${node.data.target.slug}`;
                }
                return url !== '' ? <Link to={url}>{children}</Link> : '';
            },
            [INLINES.ASSET_HYPERLINK]: (node, children) => {
                // If you are using contenful.js sdk, the referenced entry is resolved
                // automatically and is available at `node.data.target`.
                // const referencedEntry = getEntryWithId(node.data.target.sys.id);

                return <a href={`${node.data.target.file.url}`} target="_blank"
                          rel="noopener noreferrer">{children}</a>;
            },


            // INLINES
            [INLINES.HYPERLINK]: (node, children) => {
                return <BlogLink target={node.data?.uri?.charAt(0) === '#' ? '_self' : '_blank'}
                                 href={node.data?.uri}>{children}</BlogLink>
            }
        },
    }
};
